<template>
  <div
    data-cy="payment-summary"
    class="PaymentSummary"
    :class="{
      'bg-primary-1-05 text-primary-1-100': inverted,
      'text-primary-1-100 ': !inverted,
    }"
  >
    <h2 class="px-6 text-lg font-bold font-body">
      {{ $t('summary') }}
    </h2>

    <p class="flex items-center px-6 text-tiny">
      <span class="text-primary-1-60">{{ $t('subtotal') }}</span>
      <Money class="ml-auto" :value="subtotal" />
    </p>

    <slot name="extensions" />

    <p v-if="shippingFees || shippingFees === 0" class="flex items-center px-6 text-tiny">
      <span class="text-primary-1-60">{{ $t('shippingFees') }}</span>
      <Money class="ml-auto" :value="shippingFees" />
    </p>
    <p v-else class="flex items-center px-6 text-tiny">
      <span class="text-primary-1-60">{{ $t('shippingFees') }}</span>
      <Money class="ml-auto" :value="shippingFees" :fallback="$t('toBeCalculated')" />
    </p>

    <template v-if="discounts && discounts.length">
      <p v-for="({ label, value }, index) in discounts" :key="index" class="flex items-center px-6 text-tiny">
        <span class="text-primary-1-60">{{ label }}</span>
        <Money class="ml-auto" :value="value * -1" :fallback="$t('toBeCalculated')" />
      </p>
    </template>

    <p
      v-if="giftCardDetails && giftCardDetails.discounted && giftCardDetails.giftCardCode"
      class="mt-2 flex items-center px-6"
    >
      <span class="text-primary-1-60">{{ $t('giftCardDetails', { code: giftCardDetails.giftCardCode }) }}</span>

      <Money class="ml-auto" :value="giftCardDetails.discounted * -1" />
    </p>

    <div class="mx-6 bg-primary-1-10 h-unit"></div>

    <p class="flex items-center px-6 text-tiny">
      <span class="text-primary-1-60">{{ $t('total') }}</span>
      <Money class="ml-auto font-semibold" :value="total" />
    </p>

    <slot name="cta-placeholder" />

    <slot name="footer" />
  </div>
</template>

<script setup lang="ts">
defineComponent({
  name: 'PaymentSummary',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps({
  total: {
    type: Number,
    default: 0,
  },
  subtotal: {
    type: Number,
    default: 0,
  },
  shippingFees: {
    type: Number,
    default: () => null,
  },
  hasInvalidItem: {
    type: Boolean,
    default: false,
  },
  discounts: {
    type: Array as PropType<Array<{ label: string; value: number }>>,
    default: () => [],
  },
  inverted: {
    type: Boolean,
    default: false,
  },
  giftCardDetails: {
    type: Object as PropType<{ discounted: number; giftCardCode: string }>,
    default: () => ({}),
  },
});

const { t: $t } = useI18n({ useScope: 'local' });
</script>

<style lang="postcss" scoped>
.PaymentSummary {
  @apply md:self-start flex flex-col;
  top: 30px;
  row-gap: 20px;
  padding-block: 24px;
  border-radius: 28px;

  .disable-checkout {
    @apply opacity-50;
    pointer-events: none;
  }
}
</style>

<i18n>
{
  "en": {
    "summary": "Payment Summary",
    "subtotal": "Subtotal",
    "total": "Total Inclusive of VAT",
    "shippingFees": "Shipping Fees",
    "taxesIncluded": "All taxes are included",
    "proceed": "Proceed to Checkout",
    "toBeCalculated": "To Be Calculated",
    "eligibleForFreeShipping": "Eligible for free shipping",
    "giftCardDetails" : "Gift Card {code}"
  },
  "ar": {
    "summary": "تفاصيل الطلب",
    "subtotal": "الاجمالي الفرعي",
    "total": "الاجمالي يشمل قيمه الضرائب",
    "shippingFees": "مصاريف الشحن",
    "taxesIncluded": "يشمل قيمه الضرائب",
    "proceed": "المتابعه للدفع",
    "toBeCalculated": "سيتم احتسابها لاحقا",    
    "eligibleForFreeShipping": "شحن مجاني",
    "giftCardDetails" : "بطاقه الهدايا {code}"
  }
}
</i18n>
